<template>
  <div>
    <common-header :cb="headerCall"></common-header>
    <div class="account-info" v-if="userInfo">
      <div class="header-info">
        <router-link to="/account/personal" class="user-info">
          <img class="pic" :src="getImgsrc(userInfo.avatar, userInfo.wxImg)" alt="">
          <div class="phone">{{userInfo.wxName == "" ? userInfo.userNickName : userInfo.wxName}}</div>
        </router-link>
        <div class="bind-info" v-if="!userInfo.hasMpOpenId && isWeiXin() && !noAuthConfig()" @click="bindWeixin">绑定微信</div>
        <div class="bind-info" v-if="!userInfo.phoneComplete" @click="$router.push('/account/bindphone')">绑定手机号</div>

        <div class="vipInfo-icon-div">
          <img class="vipInfo-icon" :src="requestImgUrl(vipInfo.icon)" alt="">
        </div>

      </div>
      <!-- <div class="coupon-items">
        <router-link to="/">
          <div class="number">5,390</div>
          <p>可用余额</p>
        </router-link>
        <router-link to="/account/coupons">
          <div class="number">2</div>
          <p>优惠券</p>
        </router-link>
        <router-link to="/">
          <div class="number">280</div>
          <p>信用额度</p>
        </router-link>
      </div> -->
    </div>

    <div class="menu-big-box warp">
      <!-- 认证 start -->
      <div class="store-box" v-if="address != ''"  @click="$router.push(`/account/edit?id=${address.id}`)">
        <div :class="`l`">
          <div>
            <div class="tit">{{address.remark == '' ? '暂无店铺名称' : address.remark }} <span class="nameplate" :class="vipInfo.vipLevel > 0 ? 'active' : ''">{{vipInfo.vipName}}</span></div>
            <p class="ms">{{address.regionName}}{{address.address}}</p>
          </div>
        </div>
      </div>
      <div class="store-box" v-else >
        <div :class="`l`">
          <div>
            <div class="tit">暂无店铺信息</div>
          </div>
        </div>
        <router-link to="/account/edit" class="go-auth">立即添加</router-link>
      </div>
      <!-- 认证 end -->

      <div class="geren-pop">
        <div class="item" @click="$router.push(`/account/balance`)">
          <div class="num">{{userCount.balance}}</div>
          <p class="ms">余额</p>
        </div>
        <div class="item" v-if="userCount.integralArticleId" @click="$router.push(`/activity/articledetails?id=${userCount.integralArticleId}`)" data-integral="1">
          <div class="num">{{userCount.integral}}</div>
          <p class="ms">积分</p>
        </div>
        <div class="item" v-else>
          <div class="num">{{userCount.integral}}</div>
          <p class="ms">积分</p>
        </div>
        <div class="item" @click="$router.push('/account/coupons')">
          <div class="num">{{userCount.couponsCount}}</div>
          <p class="ms">优惠券</p>
        </div>
      </div>

      <!-- 我的订单 start -->
      <div class="my-order">
        <div class="base-title">
          我的订单
          <router-link to="/account/myorder" class="right">全部订单<i class="icon-hui-arrow-right"></i></router-link>
        </div>
        <div class="order-type-box">
          <router-link to="/account/myorder?status=100" class="item">
            <div class="icon-box">
              <i class="icon-account-1"></i>
              <mt-badge v-if="statistics.paying > 0" class="badge" color="#eb3030" size="small">{{statistics.paying}}</mt-badge>
            </div>
            <p>待支付</p>
          </router-link>
          <router-link to="/account/myorder?status=200" class="item">
            <div class="icon-box">
              <i class="icon-account-2"></i>
              <mt-badge v-if="statistics.delivering > 0" class="badge" color="#eb3030" size="small">{{statistics.delivering}}</mt-badge>
            </div>
            <p>待发货</p>
          </router-link>
          <router-link to="/account/myorder?status=201" class="item">
            <div class="icon-box">
              <i class="icon-account-3"></i>
              <mt-badge v-if="statistics.receiving > 0" class="badge" color="#eb3030" size="small">{{statistics.receiving}}</mt-badge>
            </div>
            <p>待收货</p>
          </router-link>
          <router-link to="/account/myorder?status=202" class="item">
            <div class="icon-box">
              <i class="icon-account-4"></i>
            </div>
            <p>已完成</p>
          </router-link>
          <router-link to="/account/myorder?status=400" class="item">
            <div class="icon-box">
              <i class="icon-account-5"></i>
              <mt-badge v-if="statistics.refund > 0" class="badge" color="#eb3030" size="small">{{statistics.refund}}</mt-badge>
            </div>
            <p>退款</p>
          </router-link>
        </div>
      </div>
      <!-- 我的订单 end -->

      <!-- 常用功能 start -->
      <div class="commonly-fun">
        <div class="base-title">
          常用功能
        </div>
        <div class="fun-box">
          <div class="item" v-for="(item, index) in h5AppList" v-if="item.type == 3">
            <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </a>
            <a :href="item.targetUrl" v-else-if="isTel(item.targetUrl)">
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </a>
            <a href="javascript:" v-else-if="item.targetUrl === 'javascript:quite()'" @click="quite">
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </a>
             <router-link :to="item.targetUrl" v-else>
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </router-link>
          </div>
         
          <!-- <div class="item" @click="quite">
            <i class="icon-gn16"></i>
            <p>退出登陆</p>
          </div> -->
        </div>
      </div>
      <!-- 常用功能 end -->

      <!-- 常用功能 start -->
      <div class="commonly-fun">
        <div class="base-title">
          帮助中心
        </div>
        <div class="fun-box">
          <div class="item" v-for="(item, index) in h5AppList" v-if="item.type == 5">
            <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </a>
            <a :href="item.targetUrl" v-else-if="isTel(item.targetUrl)">
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </a>
             <router-link :to="item.targetUrl" v-else>
              <img class="icon" :src="requestImgUrl(item.iconSelect)" alt="">
              <p>{{item.name}}</p>
            </router-link>
          </div>
        </div>
      </div>
      <!-- 常用功能 end -->
    </div>
    <tabBar></tabBar>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import avatar from "@/assets/images/img/default-headimg.jpg";
import { isHttp, isTel } from "@/utils/utils";
import { requestImgUrl, getGoodsImg, authFun, CountDown, noAuthConfig, isWeiXin } from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.isWeiXin = () => isWeiXin();
    this.noAuthConfig = () => noAuthConfig();
    this.isHttp = (v) => isHttp(v);
    this.isTel = (v) => isTel(v);
    return {
      userInfo: "",
      // 数字统计
      statistics: {},
      vipInfo: "",
      adminPhone: "",
      address: "",
      userCount: '',
      h5AppList: [],
    }
  },
  mounted() {
    this.getUserInfo().then((res)=> {
      this.userInfo = res.data;
    });
    this.getOrderSts().then((res)=> {
      this.statistics = res.data;
    })

    this.userAddressList().then((res)=> {
      this.address = res.data.length > 0 ? res.data[0] : '';
    });

    this.userCouponsCount().then((res)=> {
      this.userCount = res.data;
    })

    const orgInfo = sessionStorage.getItem("orgInfo");
    this.h5AppList = JSON.parse(orgInfo).h5AppList
    this.adminPhone = JSON.parse(orgInfo).contact
  },
  methods: {
    headerCall() {
      setTimeout(()=> {
        const VIP = sessionStorage.getItem("VIP") ? JSON.parse(sessionStorage.getItem("VIP")) : '';
        this.vipInfo = VIP;
        console.log(VIP)
      },200)
    },
    // 绑定微信
    bindWeixin() {
      const u = window.location.origin + this.$route.fullPath + "?bind=wx";
      authFun(u);
    },
    // 退出登录
    quite() {
      this.logout().then(() => {
        sessionStorage.setItem("quite", true);
        this.$Cookies.remove("user");
        this.$router.push("/guide/login");
      });
    },
    // 图片获取
    getImgsrc(v1, v2) {
      // console.log(v1, v2);
      if (v1 && v1 !== "") {
        return (
          requestImgUrl(v1) + "?x-oss-process=image/resize,m_fill,h_140,w_140"
        );
      } else if (v2) {
        return v2;
      } else {
        return avatar;
      }
    },
    ...mapActions('account', [
      "getUserInfo",
      "getOrderSts",
      "userCouponsCount",
      "getVipLevel",
    ]),
    ...mapActions("guide", ["logout", "userAddressList"]),
  },
}
</script>
<style lang='less' scoped>
.nameplate{
  font-size: .24rem;
  font-weight: normal;
  background: #ccc;
  color: #fff;
  padding: .05rem .1rem;
  margin-left: .15rem;
  border-radius: .1rem;
  &.active{
    background: #ecbd67;
  }
}
.geren-pop{
  background: #fff;
  height: 1.4rem;
  border-radius: 0.25rem;
  display: flex;
  padding: 0 0.33rem 0 0.26rem;
  margin-top: .2rem;
  align-items: center;
  .item{
    flex: 1;
    text-align: center;
    border-right: 1px solid #F2F2F2;
    &:last-child{
      border-right: none;
    }
    .num{
      font-size: .32rem;
      font-weight: bold;
      color: #000;
    }
    .ms{
      font-size: .26rem;
      margin-top: .2rem;
      color: #727272;
    }
  }
}
.header-info{
  display: flex;
  align-items: center;
  padding-top: 0.35rem;
  .bind-info{
    color: #fff;
    margin-left: .2rem;
    // text-decoration: underline;
  }
}
  .commonly-fun{
    background: #fff;
    margin-top: .2rem;
    border-radius: .25rem;
    .fun-box{
      display: flex;
      flex-wrap: wrap;
      .icon-gn16{
        display: block;
        margin: auto;
      }
      .icon{
        width: auto;
        height: .51rem;
      }
      .item{
        width: 25%;
        text-align: center;
        a{
          color: #727272;
          display: block;
        }
        font-size: .26rem;
        margin-bottom: .68rem;
        i{
          height: .51rem;
        }
        p{
          margin-top: .32rem;
        }
      }
    }
  }
  .order-type-box{
    display: flex;
    padding-bottom: .42rem;
    .item{
      flex: 1;
      text-align: center;
      .icon-box{
        height: .63rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .badge{
          position: absolute;
          top: 0;
          right: .2rem;

        }
      }
      p{
        color: #727272;
        font-size: .26rem;
        margin-top: .3rem;
      }
    }
  }
  .my-order{
    background: #fff;
    margin-top: .18rem;
    border-radius: .25rem;
  }
  .base-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .34rem;
    font-weight: 700;
    color: #212121;
    padding-top: .4rem;
    padding-left: .2rem;
    padding-right: .2rem;
    padding-bottom: .5rem;
    .right{
      font-weight: normal;
      font-size: .28rem;
      color: #727272;
      .icon-hui-arrow-right{
        margin-left: .3rem;
      }
    }
  }
  .store-box{
    background: #fff;
    height: 1.4rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    padding: 0 .33rem 0 .26rem;
    &.active{
      background-image: url('../../assets/images/img/vip-background.png');
      background-size: 100% 100%;
    }
    .l{
      flex: 1;
      display: flex;
      align-items: center;
      &.active{
        .md-icon{
          margin-right: 0.24rem;
        }
      }
    }
    .go-auth{
      font-size: .3rem;
      text-align: center;
      line-height: .62rem;
      color: #fff;
      width: 2rem;
      height: .62rem;
      border-radius: .3rem;
      background: rgba(255,150,98,1);
      background: -moz-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
      background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,150,98,1)), color-stop(91%, rgba(255,113,65,1)), color-stop(100%, rgba(255,113,65,1)));
      background: -webkit-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
      background: -o-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
      background: -ms-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
      background: linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9662', endColorstr='#ff7141', GradientType=1 );
    }
    .md-icon{
      max-width: .64rem;
      height: .59rem;
      margin-right: .14rem;
    }
    .tit{
      font-size: .3rem;
      color: #212121;
      font-weight: 700;
      line-height: 1;
      display: flex;
      align-items: center;
    }
    .ms{
      line-height: 1;
      font-size: .26rem;
      color: #8c8c8c;
      margin-top: .14rem;
    }
  }
  .menu-big-box{
    margin-top: -1rem;
    padding-bottom: .2rem;
  }
  .account-info{
    .coupon-items{
      display: flex;
      margin-top: .54rem;
      a{
        flex: 1;
        text-align: center;
        font-size: .24rem;
        color: #fff;
        .number{
          font-size: .32rem;
          font-weight: 700;
          margin-bottom: .2rem;
          line-height: 1;
        }
      }
    }
    .user-info{
      display: flex;
      color: #fff;
      font-size: .36rem;
      align-items: center;
      padding-left: .45rem;
      .pic{
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: .24rem;
      }
    }
    background: rgba(255,150,98,1);
    background: -moz-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,150,98,1)), color-stop(91%, rgba(255,113,65,1)), color-stop(100%, rgba(255,113,65,1)));
    background: -webkit-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
    background: -o-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
    background: linear-gradient(45deg, rgba(255,150,98,1) 0%, rgba(255,113,65,1) 91%, rgba(255,113,65,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9662', endColorstr='#ff7141', GradientType=1 );
    height: 2.75rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }
  .vipInfo-icon-div{
    margin-left: .2rem;
    .vipInfo-icon{
      width: .4rem;
    }
  }

</style>